  <template>
    <div class="groepsinstellingen-actions">

     <a v-bind:href="'account/'+ rowData.id + '/edit'"><button class="btn btn-primary"><i class="fas fa-edit"></i></button></a>
     <a v-bind:href="'codes/'+ rowData.id"><button class="btn btn-primary"><i class="fas fa-list"></i></button></a></a>
     <button class="btn btn-danger" v-confirm="{ok: dialog => deleteAccount(dialog, rowData.id),  okText: 'Verwijderen', cancelText : 'Annuleren',  cancel: doNothing, message: 'Weet u zeker dat u deze instelling wilt verwijderen?'}"><i class="fas fa-times"></i></button>
     
    </div>
  </template>

  <script>
  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    methods: {
      deleteAccount: function(dialog, rowID) {
      $.ajax({
        type: 'DELETE',
        url: '/account/' + rowID,
        dataType: 'json',
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        data: {id:rowID},

        success: function (data) {
          location.reload();        
        },
        error: function (data) {
          alert(" fout" );
        }
      });
      },
      doNothing: function() {
        // Do nothing or some other stuffs
      }
    }
  }
  </script>

  <style>
.dg-btn--ok {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
 }
 
 .dg-btn--cancel {
  background: #818181;
  border-color:#818181;
  color:#fff;
 }

    
    
    
    
  </style>
