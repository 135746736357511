<template>
    <div class="filter-bar">
      <form class="form-inline">
        <div class="form-group pb-2" style="width:100%">
          <input type="text" v-model="filterText" class="form-control mr-4" @keyup.enter="doFilter" placeholder="Zoeken" style="width:50%">
          <button class="btn btn-primary  mr-2" @click.prevent="doFilter">Filter</button>
          <button class="btn" @click.prevent="resetFilter">Reset</button>
        </div>
      </form>
    </div>
</template>

<script>
  export default {
    data () {
      return {
        filterText: ''
      }
    },
    methods: {
      doFilter () {
        this.$events.fire('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''
        this.$events.fire('filter-reset')
      }
    }
  }
</script>
