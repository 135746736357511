
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
require('./bootstrap');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('paginate', require('vuejs-paginate'));
// Vue.component('account', require('./components/Account.vue'));
// Vue.component('codes', require('./components/Codes.vue'));
// Vue.component('profielen', require('./components/Profiel.vue'));
// Vue.component('groepsinstellingen', require('./components/GroepsInstellingen.vue'));


// const app = new Vue({
//     el: '#app',
// });


let VueContainer = document.getElementById("app");
if(VueContainer !== null) {
	let navBar = new Vue({
		components: {
			 'paginate': Vue.component('account', require('./components/Account.vue').default),
			 'paginate': Vue.component('codes', require('./components/Codes.vue').default),
			 'paginate': Vue.component('profielen', require('./components/Profiel.vue').default),
			 'paginate': Vue.component('groepsinstellingen', require('./components/GroepsInstellingen.vue').default),

		},
		data(){
			return {}
		},
		methods:{}
	}).$mount(VueContainer)
} else {
	console.log("Could not bind Vue to VueContainer")
}
