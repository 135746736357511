<template>
  <div>
    <filter-bar></filter-bar>
    <vuetable ref="vuetable" api-url="/api/profiel?" :fields="fields" pagination-path="meta" :css="css.table"
      :sort-order="sortOrder" :multi-sort="true" detail-row-component="my-detail-row" :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"></vuetable>
    <div class="vuetable-pagination">
      <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination" :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from 'accounting'
import moment from 'moment'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Vue from 'vue'
import VueEvents from 'vue-events'
import VuejsDialog from "vuejs-dialog"
import ProfielActions from './ProfielActions'
import FilterBar from './FilterBar'
Vue.use(VueEvents)
Vue.use(VuejsDialog)
Vue.component('profiel-actions', ProfielActions)
Vue.component('filter-bar', FilterBar)


export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      fields: [
        {
          name: 'bedrijfsnaam',
          sortField: 'bedrijfsnaam'
        },
        {
          name: 'naam',
          sortField: 'naam'
        },
        {
          name: 'geboortedatum',
          sortField: 'geboortedatum'
        },
        {
          name: 'versie',
          sortField: 'versie'
        },
        {
          name: '__component:profiel-actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center'
        }
      ],
      css: {
        table: {
          tableClass: 'table table-bordered table-striped table-hover',
          ascendingIcon: 'fas fa-sort-up',
          descendingIcon: 'fas fa-sort-down'
        },
        pagination: {
          wrapperClass: 'pagination',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page',
          linkClass: 'link',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        },
        icons: {
          first: 'fas fa-backward',
          prev: 'fas fa-caret-left',
          next: 'fas fa-caret-right',
          last: 'fas fa-forward',
        },
      },
      sortOrder: [
        { field: 'email', sortField: 'email', direction: 'asc' }
      ],
      moreParams: {}
    }
  },
  methods: {
    allcap(value) {
      return value.toUpperCase()
    },
    gender(value) {
      return value === 'm'
        ? '<span class="label label-success"><i class="fas fa-male"></i> Man</span>'
        : '<span class="label label-danger"><i class="fas fa-female"></i> Vrouw</span>'
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },

    /*
    onCellClicked (data, field, event) {
      console.log('cellClicked: ', field.name)
      this.$refs.vuetable.toggleDetailRow(data.id)
    },*/



  },
  events: {
    'filter-set'(filterText) {
      this.moreParams = {
        filter: filterText
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    }
  }
}
</script>
<style>
.pagination {
  margin: 0;
  float: right;
}

.pagination a.page {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}

.pagination a.page.active {
  color: white;
  background-color: #337ab7;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}

.pagination a.btn-nav {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
}

.pagination a.btn-nav.disabled {
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
  cursor: not-allowed;
}

.pagination-info {
  float: left;
}
</style>